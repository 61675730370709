import React from 'react'
import { HStack, IconButton, Tooltip } from '@chakra-ui/react'
import { MdOutlineRestartAlt, MdRemoveRedEye } from 'react-icons/md'

interface Props {
  isModal?: boolean
  handleResetPreview: () => void
  handleOpenPreviewModal?: () => void
}

export const ToolButtonsPre: React.FC<Props> = ({
  isModal,
  handleResetPreview,
  handleOpenPreviewModal,
}) => {
  return (
    <HStack spacing="12px" justifyContent="center">
      {!isModal && (
        <Tooltip
          label="プレビュー表示"
          placement="top"
          variant="quzilla-default"
        >
          <IconButton
            aria-label="preview"
            variant="tool-btns"
            icon={<MdRemoveRedEye size="24px" />}
            onClick={() => {
              handleOpenPreviewModal?.()
            }}
          />
        </Tooltip>
      )}
      <Tooltip
        label="プレビューをリセット"
        placement="top"
        variant="quzilla-default"
      >
        <IconButton
          aria-label="reload"
          variant="tool-btns"
          icon={<MdOutlineRestartAlt size="24px" />}
          onClick={() => {
            handleResetPreview()
          }}
        />
      </Tooltip>
    </HStack>
  )
}
