import { Box } from '@chakra-ui/react'

export interface EditPreviewLayoutProps {
  pageTitle?: string
}

export const EditPreviewLayout: React.FC<EditPreviewLayoutProps> = ({
  pageTitle,
  children,
}) => {
  /* ここにLayoutを構築する
     HeaderComponent, SideNaviComponentを組み合わせる */
  return (
    <Box
      maxW="50%"
      bgColor={'#fff'}
      flex={'1 1 auto'}
      p={'24px 20px'}
      borderRadius={'24px'}
    >
      {children}
    </Box>
  )
}
