import { Center, VStack, Image, Button } from '@chakra-ui/react'
import { RouteComponentProps } from '@reach/router'
import logo from 'assets/images/login-logo.png'
import bg from 'assets/images/login-bg.png'

type Props = {
  onSignIn: () => void
} & RouteComponentProps

export const SignIn: React.FC<Props> = ({ onSignIn }) => {
  document.title = 'ログイン'

  return (
    <Center
      bg="#0086D2"
      w="100vw"
      h="100vh"
      backgroundImage={bg}
      backgroundPosition="left bottom"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    >
      <Center
        position="absolute"
        width="400px"
        height="350px"
        background="#FFFFFF"
        borderRadius="10px"
        boxShadow="0px 0px 50px 0px rgba(0, 95, 150, 0.6)"
      >
        <VStack>
          <Image src={logo} w="320px" h="75px" alt="logo" marginBottom="40px" />
          <Button
            _focus={{ boxShadow: 'none' }}
            _hover={{ backgroundColor: '#0092E7' }}
            height="48px"
            width="320px"
            color="white"
            aria-label="Search database"
            backgroundColor="#007BC2"
            onClick={onSignIn}
            borderRadius="3xl"
            fontSize="14px"
            fontWeight="bold"
          >
            ログイン
          </Button>
        </VStack>
      </Center>
    </Center>
  )
}
