import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from '@chakra-ui/react'

type Props = {
  pmIsOpen: boolean
  onClosePm: () => void
}

export const LivePreviewModal: React.FC<Props> = ({
  pmIsOpen,
  onClosePm,
  children,
}) => {
  return (
    <Modal isOpen={pmIsOpen} onClose={onClosePm}>
      <ModalOverlay />
      <ModalContent py="60px" px="24px" maxW="800px" maxH="90vh">
        <ModalCloseButton w="40px" h="40px" />
        {children}
      </ModalContent>
    </Modal>
  )
}
