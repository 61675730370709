import React, { useState, useEffect } from 'react'
import {
  Box,
  Text,
  Flex,
  IconButton,
  FormControl,
  FormLabel,
  Switch,
  useDisclosure,
} from '@chakra-ui/react'
import { MdDescription } from 'react-icons/md'
import { CloseIcon } from '@chakra-ui/icons'
import { useQuery } from '@apollo/client'
import {
  PLAYLIST,
  PlaylistData,
  PlaylistVars,
} from 'graphql/apollo/query/playlist'
import { InfoLoading } from 'v2components/InfoArea/InfoLoading'
import { InfoError } from 'v2components/InfoArea/InfoError'
import { ToolButtons } from 'v2components/Common/ToolButtons'
import { PreviewModal } from 'v2components/Modal/PreviewModal'
import { DeleteModal } from 'v2components/Modal/DeleteModal'
import { PlayQuizPlaylistPreview } from 'v2pages/PlayQuizPlaylistPreview'
import { AlertModal } from 'v2components/Modal/AlertModal'
import { useUpdateQuizlist } from 'v2hooks/useUpdateQuizlist'
import { useDeleteQuizPlaylist } from 'v2hooks/useDeleteQuizPlaylist'

interface Props {
  activeItemId: string
  closeInfoFlag: boolean
  handleClickToggleInfo: (flg: boolean) => void
}

interface ItemDetailData {
  title: string
  description: string
  published: boolean
}

export const InfoQuizlist: React.FC<Props> = ({
  activeItemId,
  closeInfoFlag,
  handleClickToggleInfo,
}) => {
  const [isCheckedPub, setIsCheckedPub] = useState(false)
  const previewDisclosure = useDisclosure()
  const deleteDisclosure = useDisclosure()
  const alertDisclosure = useDisclosure()
  const [alertMessage, setAlertMessage] = useState('')
  const isActiveId = !!activeItemId

  const itemData: ItemDetailData = {
    title: 'クイズリストを選択してください',
    description: 'クイズリストをアクティブにすると詳細データを閲覧できます。',
    published: false,
  }

  const handleClosePreviewModal = () => {
    previewDisclosure.onClose()
  }
  const handleOpenPreviewModal = () => {
    previewDisclosure.onOpen()
  }

  const handleCloseInfo = () => {
    handleClickToggleInfo?.(true)
  }

  const deleteQuizList = useDeleteQuizPlaylist(
    activeItemId,
    deleteDisclosure.onClose
  )
  const handleDeleteItem = async () => {
    try {
      await deleteQuizList()
      handleClickToggleInfo?.(true)
    } catch (error) {
      setAlertMessage('削除に失敗しました。')
      deleteDisclosure.onClose()
      alertDisclosure.onOpen()
    }
  }

  const { handleUpdateQuizlistPub } = useUpdateQuizlist({
    id: activeItemId,
  })

  const handleOpenDeleteModal = () => {
    deleteDisclosure.onOpen()
  }

  const {
    loading: quizlistLoading,
    error: quizlistError,
    data: quizlistData,
  } = useQuery<PlaylistData, PlaylistVars>(PLAYLIST, {
    skip: !isActiveId,
    variables: {
      id: activeItemId!,
    },
  })

  // itemDatsに取得したデータを入れていく
  const detailData = quizlistData?.playlist
  if (detailData) {
    itemData.title = detailData.name
    itemData.description = detailData.description ?? ''
    itemData.published = detailData.published
  }

  useEffect(() => {
    if (detailData) {
      setIsCheckedPub(detailData.published)
    }
  }, [detailData])

  return (
    <Box
      px="16px"
      py="24px"
      bgColor="#fff"
      borderRadius="24px"
      position="relative"
      w="320px"
      display={closeInfoFlag ? 'none' : 'block'}
      overflowY="scroll"
      flex="0 0 320px"
    >
      <IconButton
        isRound={true}
        variant="solid"
        bg="none"
        aria-label="Done"
        w="40px"
        h="40px"
        position="absolute"
        top="16px"
        right="8px"
        icon={<CloseIcon w="16px" h="16px" color="#222" />}
        _hover={{
          bgColor: '#C4C7CC',
        }}
        onClick={handleCloseInfo}
      />
      {itemData.title && (
        <Box fontWeight="bold" lineHeight="1.7" display="flex" pr="40px">
          <MdDescription color="#0086D2" size="24px" />
          <Text display="inline-block" ml="8px">
            {itemData.title}
          </Text>
        </Box>
      )}
      {itemData.description && (
        <Text fontSize="12px" lineHeight="1.66" mt="8px" pl="32px" pr="40px">
          {itemData.description}
        </Text>
      )}

      {(() => {
        if (quizlistLoading) return <InfoLoading />
        if (activeItemId && quizlistError) return <InfoError />
      })()}
      {activeItemId && detailData && (
        <>
          <Box mt="20px">
            <PlayQuizPlaylistPreview
              quizPlaylistId={activeItemId}
              isEmbed={true}
            />
          </Box>
          <Flex justifyContent="flex-end" mt="12px">
            <FormControl display="flex" alignItems="center" w="auto">
              <Switch
                id="publish-switch"
                size="sm"
                colorScheme="green"
                isChecked={isCheckedPub}
                onChange={async (e) => {
                  setIsCheckedPub(!isCheckedPub)
                  try {
                    await handleUpdateQuizlistPub(!isCheckedPub)
                  } catch (error) {
                    setIsCheckedPub(isCheckedPub)
                    setAlertMessage('公開ステータス変更に失敗しました。')
                    alertDisclosure.onOpen()
                  }
                }}
              />
              <FormLabel
                htmlFor="publish-switch"
                mb="0"
                fontSize="14px"
                ml="8px"
              >
                {isCheckedPub ? '公開' : '非公開'}
              </FormLabel>
            </FormControl>
          </Flex>

          <Box mt="20px">
            <ToolButtons
              itemId={activeItemId}
              contentType="quizlist"
              buttonType="item-info"
              handleOpenPreviewModal={() => {
                handleOpenPreviewModal()
              }}
              handleOpenDeleteModal={handleOpenDeleteModal}
            />
          </Box>
          <PreviewModal
            pmIsOpen={previewDisclosure.isOpen}
            onClosePm={handleClosePreviewModal}
            itemId={activeItemId}
            contentType="quizlist"
          />
          <DeleteModal
            dmIsOpen={deleteDisclosure.isOpen}
            dmOnClose={deleteDisclosure.onClose}
            handleDeleteItem={handleDeleteItem}
          />
          <AlertModal
            modalBody={alertMessage}
            isOpen={alertDisclosure.isOpen}
            onClose={alertDisclosure.onClose}
          />
        </>
      )}
    </Box>
  )
}
