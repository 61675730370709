import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { Center, VStack, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { ProgressStatus } from 'hooks/usePlayQuizPlaylist'
import { usePlayQuizPlaylistPreview } from 'hooks/usePlayQuizPlaylistPreview'
import { Start } from 'components/PlayQuizPlaylist/Start'
import { Question } from 'components/PlayQuizPlaylist/Question'
import { Answered } from 'components/PlayQuizPlaylist/Answered'
import { Result } from 'components/PlayQuizPlaylist/Result'
import { EmbedStart } from 'components/PlayQuizPlaylist/EmbedStart'
import { EmbedQuestion } from 'components/PlayQuizPlaylist/EmbedQuestion'
import { EmbedAnswered } from 'components/PlayQuizPlaylist/EmbedAnswered'
import { EmbedResult } from 'components/PlayQuizPlaylist/EmbedResult'
import { InfoLoading } from 'v2components/InfoArea/InfoLoading'
import {
  QUIZSTATS,
  QuizStatsData,
  QuizStatsVars,
} from 'graphql/apollo/query/quizStats'

type urlProps = RouteComponentProps<{
  quizPlaylistId: string
  isEmbed: boolean
}>

export const PlayQuizPlaylistPreview: React.FC<urlProps> = ({
  quizPlaylistId = '',
  isEmbed = false,
}) => {
  const {
    loading,
    refetch,
    isOwnedQuizPlaylist,
    progressStatus,
    playlist,
    quizNo,
    answers,
    score,
    currentQuiz,
    quizzesCount,
    handleStart,
    handleAnswer,
    handleNext,
  } = usePlayQuizPlaylistPreview(quizPlaylistId)
  const isActiveId = !!currentQuiz?.id

  refetch({ id: quizPlaylistId })

  const { loading: quizStatsLoading, data: quizStatsData } = useQuery<
    QuizStatsData,
    QuizStatsVars
  >(QUIZSTATS, {
    skip: !isActiveId,
    variables: {
      quizId: currentQuiz?.id!,
    },
    fetchPolicy: 'network-only',
  })

  const designTheme = 'NONE'

  if (quizStatsLoading) {
    return <InfoLoading />
  }
  if (!currentQuiz)
    return (
      <Center w="100%" h="200px" borderRadius="16px" bgColor="#efefef">
        <VStack>
          <Text>リスト内クイズがすべて非公開です</Text>
        </VStack>
      </Center>
    )
  if (!isOwnedQuizPlaylist)
    return (
      <Center w="100%" h="200px" borderRadius="16px" bgColor="#efefef">
        <VStack>
          <Text>アクセスする権限がありません</Text>
        </VStack>
      </Center>
    )

  const renderPageComponent = () => {
    switch (progressStatus) {
      case ProgressStatus.START:
        if (isEmbed) {
          return (
            <EmbedStart
              isLoading={loading}
              playlist={playlist}
              quizzesCount={quizzesCount}
              onStart={handleStart}
            />
          )
        }
        return (
          <Start
            isLoading={loading}
            playlist={playlist}
            quizzesCount={quizzesCount}
            design={designTheme}
            onStart={handleStart}
          />
        )
      case ProgressStatus.QUESTION:
        if (isEmbed) {
          return (
            <EmbedQuestion
              quiz={currentQuiz}
              quizNo={quizNo}
              quizzesCount={quizzesCount}
              onAnswer={handleAnswer}
              quizStats={quizStatsData?.quizStats}
            />
          )
        }
        return (
          <Question
            quiz={currentQuiz}
            quizNo={quizNo}
            quizzesCount={quizzesCount}
            design={designTheme}
            onAnswer={handleAnswer}
            quizStats={quizStatsData?.quizStats}
          />
        )
      case ProgressStatus.ANSWERD:
        if (isEmbed) {
          return (
            <EmbedAnswered
              quiz={currentQuiz}
              answer={answers[answers.length - 1]}
              onNext={handleNext}
              quizStats={quizStatsData?.quizStats}
            />
          )
        }
        return (
          <Answered
            quiz={currentQuiz}
            quizNo={quizNo}
            quizzesCount={quizzesCount}
            answer={answers[answers.length - 1]}
            design={designTheme}
            onNext={handleNext}
            quizStats={quizStatsData?.quizStats}
          />
        )
      case ProgressStatus.RESULT:
        if (isEmbed) {
          return (
            <EmbedResult
              playlist={playlist!}
              quizzesCount={quizzesCount}
              answers={answers}
              score={score}
            />
          )
        }
        return (
          <Result
            playlist={playlist!}
            quizzesCount={quizzesCount}
            answers={answers}
            score={score}
            design={designTheme}
          />
        )
    }
  }

  return loading ? <InfoLoading /> : renderPageComponent()
}
